import { SET_API_ERROR, CLEAR_API_ERROR } from './errorState.js';

// Actions
const GET_BASINS_REQUEST = 'GET_BASINS_REQUEST';
const GET_BASINS_SUCCESS = 'GET_BASINS_SUCCESS';
const GET_BASINS_FAILURE = 'GET_BASINS_FAILURE';

const GET_BASIN_REQUEST = 'GET_BASIN_REQUEST';
const GET_BASIN_SUCCESS = 'GET_BASIN_SUCCESS';
const GET_BASIN_FAILURE = 'GET_BASIN_FAILURE';

const GET_MANAGEMENT_CATCHMENT_REQUEST = 'GET_MANAGEMENT_CATCHMENT_REQUEST';
const GET_MANAGEMENT_CATCHMENT_SUCCESS = 'GET_MANAGEMENT_CATCHMENT_SUCCESS';
const GET_MANAGEMENT_CATCHMENT_FAILURE = 'GET_MANAGEMENT_CATCHMENT_FAILURE';

const GET_OPERATIONAL_CATCHMENT_REQUEST = 'GET_OPERATIONAL_CATCHMENT_REQUEST';
const GET_OPERATIONAL_CATCHMENT_SUCCESS = 'GET_OPERATIONAL_CATCHMENT_SUCCESS';
const GET_OPERATIONAL_CATCHMENT_FAILURE = 'GET_OPERATIONAL_CATCHMENT_FAILURE';

const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

const GET_FLOODMAPPER_COVERAGE_AREAS_REQUEST = 'GET_FLOODMAPPER_COVERAGE_AREAS_REQUEST';
const GET_FLOODMAPPER_COVERAGE_AREAS_SUCCESS = 'GET_FLOODMAPPER_COVERAGE_AREAS_SUCCESS';
const GET_FLOODMAPPER_COVERAGE_AREAS_FAILURE = 'GET_FLOODMAPPER_COVERAGE_AREAS_FAILURE';

const GET_FLOODMAPPER_SUBDOMAIN_REQUEST = 'GET_FLOODMAPPER_SUBDOMAIN_REQUEST';
const GET_FLOODMAPPER_SUBDOMAIN_SUCCESS = 'GET_FLOODMAPPER_SUBDOMAIN_SUCCESS';
const GET_FLOODMAPPER_SUBDOMAIN_FAILURE = 'GET_FLOODMAPPER_SUBDOMAIN_FAILURE';

const GET_STATION_REQUEST = 'GET_STATION_REQUEST';
const GET_STATION_SUCCESS = 'GET_STATION_SUCCESS';
const GET_STATION_FAILURE = 'GET_STATION_FAILURE';

const GET_WATERBODY_REQUEST = 'GET_WATERBODY_REQUEST';
const GET_WATERBODY_SUCCESS = 'GET_WATERBODY_SUCCESS';
const GET_WATERBODY_FAILURE = 'GET_WATERBODY_FAILURE';

const GET_FLOODALERTAREA_REQUEST = 'GET_FLOODALERTAREA_REQUEST';
const GET_FLOODALERTAREA_SUCCESS = 'GET_FLOODALERTAREA_SUCCESS';
const GET_FLOODALERTAREA_FAILURE = 'GET_FLOODALERTAREA_FAILURE';

const GET_VIEW_HOME_REQUEST = 'GET_VIEW_HOME_REQUEST';
const GET_VIEW_HOME_SUCCESS = 'GET_VIEW_HOME_SUCCESS';
const GET_VIEW_HOME_FAILURE = 'GET_VIEW_HOME_FAILURE';

const GET_FLOODALERTSUMMARY_REQUEST = 'GET_FLOODALERTSUMMARY_REQUEST';
const GET_FLOODALERTSSUMMARY_SUCCESS = 'GET_FLOODALERTSSUMMARY_SUCCESS';
const GET_FLOODALERTSSUMMARY_FAILURE = 'GET_FLOODALERTSSUMMARY_FAILURE';

const GET_SEARCHPLACE_REQUEST = 'GET_SEARCHPLACE_REQUEST';
const GET_SEARCHPLACE_SUCCESS = 'GET_SEARCHPLACE_SUCCESS';
const GET_SEARCHPLACE_FAILURE = 'GET_SEARCHPLACE_FAILURE';

const GET_PLACEDEFENCES_REQUEST = 'GET_PLACEDEFENCES_REQUEST';
const GET_PLACEDEFENCES_SUCCESS = 'GET_PLACEDEFENCES_SUCCESS';
const GET_PLACEDEFENCES_FAILURE = 'GET_PLACEDEFENCES_FAILURE';


const GET_STATION_HISTORICAL_REQUEST = 'GET_STATION_HISTORICAL_REQUEST';
const GET_STATION_HISTORICAL_SUCCESS = 'GET_STATION_HISTORICAL_SUCCESS';
const GET_STATION_HISTORICAL_FAILURE = 'GET_STATION_HISTORICAL_FAILURE';


const GET_STATION_HISTORICAL_WITH_STARTTIME_REQUEST = 'GET_STATION_HISTORICAL_WITH_STARTTIME_REQUEST';
const GET_STATION_HISTORICAL_WITH_STARTTIME_SUCCESS = 'GET_STATION_HISTORICAL_WITH_STARTTIME_SUCCESS';
const GET_STATION_HISTORICAL_WITH_STARTTIME_FAILURE = 'GET_STATION_HISTORICAL_WITH_STARTTIME_FAILURE';

const GET_SEARCH_LOCAL_REQUEST = 'GET_SEARCH_LOCAL_REQUEST';
const GET_SEARCH_LOCAL_SUCCESS = 'GET_SEARCH_LOCAL_SUCCESS';
const GET_SEARCH_LOCAL_FAILURE = 'GET_SEARCH_LOCAL_FAILURE';

const GET_SEARCH_SEWAGE_REQUEST = 'GET_SEARCH_SEWAGE_REQUEST';
const GET_SEARCH_SEWAGE_SUCCESS = 'GET_SEARCH_SEWAGE_SUCCESS';
const GET_SEARCH_SEWAGE_FAILURE = 'GET_SEARCH_SEWAGE_FAILURE';

const TRACK_REQUEST_START_BY_TRACE = 'TRACK_REQUEST_START_BY_TRACE';
const TRACK_REQUEST_END_BY_TRACE = 'TRACK_REQUEST_END_BY_TRACE';

const CLEAR_ACTIVE_FLOODNAV = 'CLEAR_ACTIVE_FLOODNAV';

const GET_MAPPING_STATIONS_REQUEST = 'GET_MAPPING_STATIONS_REQUEST';
const GET_MAPPING_STATIONS_SUCCESS = 'GET_MAPPING_STATIONS_SUCCESS';
const GET_MAPPING_STATIONS_FAILURE = 'GET_MAPPING_STATIONS_FAILURE';



const GET_RIVER_SUB_UNIT_REQUEST = 'GET_RIVER_SUB_UNIT_REQUEST';
const GET_RIVER_SUB_UNIT_SUCCESS = 'GET_RIVER_SUB_UNIT_SUCCESS';
const GET_RIVER_SUB_UNIT_FAILURE = 'GET_RIVER_SUB_UNIT_FAILURE';

const GET_STATION_FLOWMODEL_REQUEST = 'GET_STATION_FLOWMODEL_REQUEST';
const GET_STATION_FLOWMODEL_SUCCESS = 'GET_STATION_FLOWMODEL_SUCCESS';
const GET_STATION_FLOWMODEL_FAILURE = 'GET_STATION_FLOWMODEL_FAILURE';

export const baseUrl = process.env.REACT_APP_FMUK_BASE_API;
const floodAreaDocumentId = process.env.REACT_APP_FLOODAREAS_DOCUMENT_ID;

// const genericApiRequest = () => {
// 	return dispatch
// }



export const reissueSLTUsingLLT = () => {
  return async(dispatch, getState) => {
    const { APP_SET_SLT, APP_SET_LLT, APP_SET_USER_ID, TOAST_USER } = await import('./cdvState.js');
    dispatch({ type: TRACK_REQUEST_START_BY_TRACE, callerTrace: 'FMUK_INTERNALS_LLT_REFRESHTOKEN' }); // make into a mutex
    let forceUserLogout = false;
    if(getState().cdvState && getState().cdvState.userId && getState().cdvState.llt) {
      console.log("Has LLT - reissue request now");
      
      try {
        let response = await fetch(`${baseUrl}/cdv/user/${getState().cdvState.userId}/slt/refresh`, {
          method: 'GET',
          headers: {
            'authorization': 'Bearer: ' + getState().cdvState.llt
          }
        });
        if(!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        let data = await response.json();
        if(data && data.slt) {
          dispatch(APP_SET_SLT({ slt: data.slt }));
        }
      } catch(error) {
        console.error("Did not issue new SLT", JSON.stringify(error));
        forceUserLogout = true;
      }
    } else {
      console.log("User does not have the sufficient tokens to be able to sign in");
      forceUserLogout = true;
    }


    if(forceUserLogout) {
      console.log("Session expired or invalid - logging out");
      dispatch(APP_SET_USER_ID({ userId: null }));
      dispatch(APP_SET_LLT({ llt: null }));
      dispatch(APP_SET_SLT({ slt: null }));
      const { Toast } = await import('@capacitor/toast');
      await Toast.show({
        text: 'Login session expired. Please sign in again.',
        duration: 'long',
        position: 'bottom'
      })
    }

    dispatch({ type: TRACK_REQUEST_END_BY_TRACE, callerTrace: 'FMUK_INTERNALS_LLT_REFRESHTOKEN' });
  }
}

const fetchData = (dispatch, requestType, successType, failureType, url, extraData = {}, callerTrace, callerCallback, attempt = 1) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: requestType, callerTrace: callerTrace });

    if(attempt === 1 && callerTrace) {
      dispatch({ type: TRACK_REQUEST_START_BY_TRACE, callerTrace: callerTrace });
    }

    fetch(url)
      .then(response => {
        if (!response.ok) {
          console.error("Req'd: ", url);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        dispatch({ ...{ type: successType, payload: data, callerTrace: callerTrace }, ...extraData });
        dispatch({ type: TRACK_REQUEST_END_BY_TRACE, callerTrace: callerTrace });
        dispatch(CLEAR_API_ERROR({ callerTrace: callerTrace }));
        if(callerCallback) {
          callerCallback(null);
        }
        return resolve(data);
      })
      .catch(error => {
        if (attempt < 3) {
          setTimeout(() => {
            fetchData(dispatch, requestType, successType, failureType, url, extraData, callerTrace, callerCallback, attempt + 1)
              .then(resolve)
              .catch(reject);
          }, 750);
        } else {
          dispatch({ ...{ type: failureType, payload: error, callerTrace: callerTrace }, ...extraData });
          dispatch(SET_API_ERROR({ callerTrace: callerTrace }));
          dispatch({ type: TRACK_REQUEST_END_BY_TRACE, callerTrace: callerTrace });
          if(callerCallback) {
            callerCallback(true);
          }
          reject(error);
        }
      });
  });
};

const fetchDataCustom = (dispatch, getState, requestType, successType, failureType, requestMethod, requestHeaders, url, requestBody, requestRequireAuth, extraData = {}, callerTrace, callerCallback, attempt = 1) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: requestType, callerTrace: callerTrace });
    requestHeaders = requestHeaders ? requestHeaders : {};

    if(attempt === 1 && callerTrace) {
      dispatch({ type: TRACK_REQUEST_START_BY_TRACE, callerTrace: callerTrace });
    }

    if(requestBody && !requestHeaders['Content-Type']) {
      requestHeaders['Content-Type'] = 'application/json';
    }

    if(requestRequireAuth) {
      console.log("Making authenticated request");
      // if(!requestHeaders['authorization']) {
        const cdvState = getState().cdvState;
        if(cdvState && cdvState.slt) {
          requestHeaders['authorization'] = 'Bearer: ' + cdvState.slt;  // allow this to override each try incase a refresh happened somewhere
        } else {
          console.log("No short tokens to use, trying without slt to see if we can refresh using llt.");
        }
      // } else {
        // console.log("Auth header already present, not overriding.");
      // }
    }

    fetch(url, {
      method: requestMethod,
      body: requestBody ? JSON.stringify(requestBody) : null,
      headers: requestHeaders
      }).then(response => {
        if (!response.ok) {
          console.error("Req'd (w/ body): ", url);
          if(requestRequireAuth && (response.status === 401 || response.status === 403)) {
            // right we are here, we tried an auth it didn't work, lets issue an LLT->SLT refreshToken request
            console.log("Authenticated endpoint returned unauth code, LLT refresh");
            dispatch(reissueSLTUsingLLT());
          }
          throw new Error('Network response was not ok');
        }
        console.log(response);
        return response.json();
      })
      .then(data => {
        dispatch({ ...{ type: successType, payload: data, callerTrace: callerTrace }, ...extraData });
        dispatch({ type: TRACK_REQUEST_END_BY_TRACE, callerTrace: callerTrace });
        dispatch(CLEAR_API_ERROR({ callerTrace: callerTrace }));
        if(callerCallback) {
          callerCallback(null);
        }
        return resolve(data);
      })
      .catch(error => {
        if (attempt < 3) {
          setTimeout(() => {
            fetchDataCustom(dispatch, getState, requestType, successType, failureType, requestMethod, requestHeaders, url, requestBody, requestRequireAuth, extraData, callerTrace, callerCallback, attempt + 1)
              .then(resolve)
              .catch(reject);
          }, 750);
        } else {
          dispatch({ ...{ type: failureType, payload: error, callerTrace: callerTrace }, ...extraData });
          dispatch(SET_API_ERROR({ callerTrace: callerTrace }));
          dispatch({ type: TRACK_REQUEST_END_BY_TRACE, callerTrace: callerTrace });
          if(callerCallback) {
            callerCallback(true);
          }
          reject(error);
        }
      });
  });
};

export const getMappingStations = (callerTrace, countryCode, callerCallback) => {
  return async(dispatch) => {
    try {
      await fetchData(dispatch, GET_MAPPING_STATIONS_REQUEST, GET_MAPPING_STATIONS_SUCCESS, GET_MAPPING_STATIONS_FAILURE, `${baseUrl}/data/mapping/stations` + (countryCode ? '/'+countryCode : ""), null, callerTrace || "untraced::getMappingStations", callerCallback);
    } catch(error) {
      console.error("Could not fetch map data for stations", error);
    }
  }
}

export const getHomePageView = (callerTrace) => {
  return async (dispatch) => {
    try {
      await fetchData(dispatch, GET_VIEW_HOME_REQUEST, GET_VIEW_HOME_SUCCESS, GET_VIEW_HOME_FAILURE, `${baseUrl}/views/home`, null, callerTrace || "untraced::getHomePageView");
    } catch (error) {
      console.error("Error fetching home page view:", error);
    }
  };
};

export const getSearchLocalByPlaceId = (placeId, callerTrace, callerCallback) => {
  return async (dispatch) => {
    try {
      await fetchData(dispatch, GET_SEARCH_LOCAL_REQUEST, GET_SEARCH_LOCAL_SUCCESS, GET_SEARCH_LOCAL_FAILURE, `${baseUrl}/data/geographical/place/${placeId}/searchResult`, null, callerTrace || "untraced::getHomePageView", callerCallback);
    } catch (error) {
      console.error("Error fetching search place view:", error);
    }
  };
};


export const getSearchSewageByPlaceId = (placeId, callerTrace, callerCallback) => {
  return async (dispatch) => {
    try {
      await fetchData(dispatch, GET_SEARCH_SEWAGE_REQUEST, GET_SEARCH_SEWAGE_SUCCESS, GET_SEARCH_SEWAGE_FAILURE, `${baseUrl}/data/geographical/place/${placeId}/sewageResult`, null, callerTrace || "untraced::getHomePageView", callerCallback);
    } catch (error) {
      console.error("Error fetching search place view:", error);
    }
  };
};

const GET_APP_VIEW_HOME_REQUEST = 'cdvState/GET_APP_VIEW_HOME_REQUEST';
const GET_APP_VIEW_HOME_SUCCESS = 'cdvState/GET_APP_VIEW_HOME_SUCCESS';
const GET_APP_VIEW_HOME_FAILURE = 'cdvState/GET_APP_VIEW_HOME_FAILURE';
export const getAppHomePageView = (userId, callerTrace) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, GET_APP_VIEW_HOME_REQUEST, GET_APP_VIEW_HOME_SUCCESS, GET_APP_VIEW_HOME_FAILURE, 'GET', null, `${baseUrl}/cdv/user/${userId}`, null, true, null, callerTrace || "untraced::getAppHomePageView");
    } catch (error) {
      console.error("Error fetching home page view (app):", error);
    }
  };
};

const USER_DELETE_REQUEST = 'cdvState/USER_DELETE_REQUEST';
const USER_DELETE_SUCCESS = 'cdvState/USER_DELETE_SUCCESS';
const USER_DELETE_FAILURE = 'cdvState/USER_DELETE_FAILURE';
export const deleteAppUser = (userId, callerTrace) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE, 'DELETE', null, `${baseUrl}/cdv/user/${userId}`, null, true, null, callerTrace || "untraced::deleteAppUser");
    } catch (error) {
      console.error("Error delete user (app):", error);
    }
  };
};

const USER_FAA_REQUEST = 'cdvState/USER_FAA_REQUEST';
const USER_FAA_SUCCESS = 'cdvState/USER_FAA_SUCCESS';
const USER_FAA_FAILURE = 'cdvState/USER_FAA_FAILURE';
export const setAppUserFloodAlertArea = (userId, floodAlertAreaId, pushEnable, callerTrace, callerCallback) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, USER_FAA_REQUEST, USER_FAA_SUCCESS, USER_FAA_FAILURE, 'PUT', null, `${baseUrl}/cdv/user/${userId}/faa/${floodAlertAreaId}`, { pushEnable: pushEnable }, true, null, callerTrace || "untraced::setAppUserFloodAlertArea", callerCallback);
    } catch (error) {
      console.error("Error put user flood alert area (app):", error);
    }
  };
};

const USER_FAA_DELETE_REQUEST = 'cdvState/USER_FAA_DELETE_REQUEST';
const USER_FAA_DELETE_SUCCESS = 'cdvState/USER_FAA_DELETE_SUCCESS';
const USER_FAA_DELETE_FAILURE = 'cdvState/USER_FAA_DELETE_FAILURE';
export const deleteAppUserFloodAlertArea = (userId, floodAlertAreaId, callerTrace, callerCallback) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, USER_FAA_DELETE_REQUEST, USER_FAA_DELETE_SUCCESS, USER_FAA_DELETE_FAILURE, 'DELETE', null, `${baseUrl}/cdv/user/${userId}/faa/${floodAlertAreaId}`, null, true, null, callerTrace || "untraced::deleteAppUserFloodAlertArea", callerCallback);
    } catch (error) {
      console.error("Error delete user flood alert area (app):", error);
    }
  };
};

const USER_METRIC_REQUEST = 'cdvState/USER_METRIC_REQUEST';
const USER_METRIC_SUCCESS = 'cdvState/USER_METRIC_SUCCESS';
const USER_METRIC_FAILURE = 'cdvState/USER_METRIC_FAILURE';
export const setAppUserMetric = (userId, metricId, callerTrace, callerCallback) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, USER_METRIC_REQUEST, USER_METRIC_SUCCESS, USER_METRIC_FAILURE, 'PUT', null, `${baseUrl}/cdv/user/${userId}/metrics/${metricId}`, null, true, null, callerTrace || "untraced::setAppUserMetric", callerCallback);
    } catch(error) {
      console.error("Error put user metric (app):", error);
    }
  };
};

const USER_METRIC_DELETE_REQUEST = 'cdvState/USER_METRIC_DELETE_REQUEST';
const USER_METRIC_DELETE_SUCCESS = 'cdvState/USER_METRIC_DELETE_SUCCESS';
const USER_METRIC_DELETE_FAILURE = 'cdvState/USER_METRIC_DELETE_FAILURE';
export const deleteAppUserMetric = (userId, metricId, callerTrace, callerCallback) => {
  return async (dispatch, getState) => {
    try {
      await fetchDataCustom(dispatch, getState, USER_METRIC_DELETE_REQUEST, USER_METRIC_DELETE_SUCCESS, USER_METRIC_DELETE_FAILURE, 'DELETE', null, `${baseUrl}/cdv/user/${userId}/metrics/${metricId}`, null, true, null, callerTrace || "untraced::deleteAppUserMetric", callerCallback);
    } catch(error) {
      console.error("Error delete user metric (app): ", error);
    }
  };
};

export const getFloodMapperCoverageAreas = () => {
  return async (dispatch) => {
    try {
      await fetchData(dispatch, GET_FLOODMAPPER_COVERAGE_AREAS_REQUEST, GET_FLOODMAPPER_COVERAGE_AREAS_SUCCESS, GET_FLOODMAPPER_COVERAGE_AREAS_FAILURE, `${baseUrl}/documents/${floodAreaDocumentId}`, null, "untraced::getFloodMapperCoverageAreas");
    } catch(error) {
      console.error("Error fetching floodnav view: ", error);
    }
  }
};

// export const getFloodMapForAreaByName = (areaName, setAsActiveArea) => dispatch => {
//   fetchData(dispatch, GET_FLOODMAPPER_SUBDOMAIN_REQUEST, GET_FLOODMAPPER_SUBDOMAIN_SUCCESS, GET_FLOODMAPPER_SUBDOMAIN_FAILURE, `${baseUrl}/views/area/${areaName}`, { areaName, setAsActiveArea: !!setAsActiveArea }, "untraced::getFloodMapForAreaByName");
// };

export const getFloodMapForAreaByName = (areaName, setAsActiveArea) => {
  return async (dispatch) => {
    try {
      await fetchData(dispatch, GET_FLOODMAPPER_SUBDOMAIN_REQUEST, GET_FLOODMAPPER_SUBDOMAIN_SUCCESS, GET_FLOODMAPPER_SUBDOMAIN_FAILURE, `${baseUrl}/views/area/${areaName}`, { areaName, setAsActiveArea: !!setAsActiveArea }, "untraced::getFloodMapForAreaByName");
    } catch(error) {
      console.error("Error fetching floodnav view: ", error);
    }
  }
}


export const getStationById = (stationId, qualiferType, metricId, callerTrace) => {
  return async (dispatch) => {
    let suffix = "";
    switch(qualiferType) {
      case 'Downstream Stage':
        suffix = '/downstream';
        break;
      case 'Flow':
        suffix = '/flow';
        break;
      case 'Rainfall':
        suffix = '/rainfall'
        break;
      case 'WaterTemperature':
        suffix = '/water-temperature'
        break;
    };

    if(metricId) {
      suffix = '/metric/' + metricId
    }
    await fetchData(
      dispatch, 
      GET_STATION_REQUEST, 
      GET_STATION_SUCCESS, 
      GET_STATION_FAILURE, 
      `${baseUrl}/data/geographical/stations/${stationId}${suffix}`,
      null,
      callerTrace ? callerTrace : "untraced::getStationById"
    );
  };
};

export const getStationFlowModelById = (stationId, callerTrace, callerCallback) => {
  return async (dispatch) => {
    await fetchData(
      dispatch,
      GET_STATION_FLOWMODEL_REQUEST,
      GET_STATION_FLOWMODEL_SUCCESS,
      GET_STATION_FLOWMODEL_FAILURE,
      `${baseUrl}/data/geographical/stations/${stationId}/flow/model`,
      null,
      callerTrace ? callerTrace : 'untraced::getStationFlowModelById',
      callerCallback
    );
  }
};

export const getStationHistoricalById = (stationId, callerTrace) => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch,
        GET_STATION_HISTORICAL_REQUEST,
        GET_STATION_HISTORICAL_SUCCESS,
        GET_STATION_HISTORICAL_FAILURE,
        `${baseUrl}/data/geographical/stations/${stationId}/historical`,
        null,
        callerTrace ? callerTrace : "untraced::getStationHistoricalById"
      );
    } catch(error) {
      console.error("Error getting historical station data", error);
    }
  }
};

export const getStationHistoricalByIdAndStartTime = (stationId, dateTime, callerTrace, callerCallback) => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch,
        GET_STATION_HISTORICAL_WITH_STARTTIME_REQUEST,
        GET_STATION_HISTORICAL_WITH_STARTTIME_SUCCESS,
        GET_STATION_HISTORICAL_WITH_STARTTIME_FAILURE,
        `${baseUrl}/data/geographical/stations/${stationId}/historical/${dateTime}`,
        null,
        callerTrace ? callerTrace : "untraced::getStationHistoricalByIdAndStartTime",
        callerCallback
      );
    } catch(error) {
      console.error("Error getting historical flood data for station", error);
    }
  }
};

export const getFloodAlertSummary = (callerTrace) => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_FLOODALERTSUMMARY_REQUEST, 
        GET_FLOODALERTSSUMMARY_SUCCESS, 
        GET_FLOODALERTSSUMMARY_FAILURE, 
        `${baseUrl}/views/floodAlerts/Summary`,
        null,
        callerTrace ? callerTrace : "untraced::getFloodAlertSummary"
      );
    } catch(error) {
      console.error("Error getting /flood-alerts summary page", error);
    }
  }
};


export const getFloodAlertAreaById = (floodAlertAreaId) => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_FLOODALERTAREA_REQUEST, 
        GET_FLOODALERTAREA_SUCCESS, 
        GET_FLOODALERTAREA_FAILURE, 
        `${baseUrl}/data/geographical/floodAlert/${floodAlertAreaId}`,
        null,
        "untraced::getFloodAlertAreaById"
      );
    } catch(error) {
      console.error("Error getting flood alert area", error);
    }
  }
};


// Action Creators
export const getBasins = () => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_BASINS_REQUEST, 
        GET_BASINS_SUCCESS, 
        GET_BASINS_FAILURE, 
        `${baseUrl}/data/geographical/basins`,
        null,
        "untraced::getBasins"
      );
    } catch(error) {
      console.error("Error getting river basin list", error);
    }
  }
}

export const getBasin = (basinId) => dispatch => {
  fetchData(
    dispatch, 
    GET_BASIN_REQUEST, 
    GET_BASIN_SUCCESS, 
    GET_BASIN_FAILURE, 
    `${baseUrl}/data/geographical/basins/${basinId}`,
    null,
    "untraced::getBasin"
  );
};

export const getBasinByName = (basinName) => {
  return async(dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_BASIN_REQUEST, 
        GET_BASIN_SUCCESS, 
        GET_BASIN_FAILURE, 
        `${baseUrl}/data/geographical/byName/basins/${basinName}`,
        null,
        "untraced::getBasinByName"
      );
    } catch(error) {
      console.error("Error retreiving basin by name", error);
    }
  }
};

export const getManagementCatchment = (managementCatchmentId) => dispatch => {
  fetchData(
    dispatch, 
    GET_MANAGEMENT_CATCHMENT_REQUEST, 
    GET_MANAGEMENT_CATCHMENT_SUCCESS, 
    GET_MANAGEMENT_CATCHMENT_FAILURE, 
    `${baseUrl}/data/geographical/managementCatchments/${managementCatchmentId}`,
    null,
    "untraced::getManagementCatchment"
  );
};

export const getManagementCatchmentByName = (managementCatchmentName) => {
  return async (dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_MANAGEMENT_CATCHMENT_REQUEST, 
        GET_MANAGEMENT_CATCHMENT_SUCCESS, 
        GET_MANAGEMENT_CATCHMENT_FAILURE, 
        `${baseUrl}/data/geographical/byName/managementCatchments/${managementCatchmentName}`,
        null,
        "untraced::getManagementCatchmentByName"
      );
    } catch(error) {
      console.error("Error retreiving management catchment information", error);
    }
  }
};

//
export const getRiverSubUnitByName = (riverSubUnitName) => {
  return async (dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_RIVER_SUB_UNIT_REQUEST, 
        GET_RIVER_SUB_UNIT_SUCCESS, 
        GET_RIVER_SUB_UNIT_FAILURE, 
        `${baseUrl}/data/geographical/byName/riverSubUnits/${riverSubUnitName}`,
        null,
        "untraced::getRiverSubUnitByName"
      );
    } catch(error) {
      console.error("Error retreiving river sub unit information", error);
    }
  }
};

export const getOperationalCatchment = (operationalCatchmentId) => {
  return async (dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_OPERATIONAL_CATCHMENT_REQUEST, 
        GET_OPERATIONAL_CATCHMENT_SUCCESS, 
        GET_OPERATIONAL_CATCHMENT_FAILURE, 
        `${baseUrl}/data/geographical/operationalCatchments/${operationalCatchmentId.replaceAll('/', '-')}`,
        null,
        "untraced::getOperationalCatchment"
      );
    } catch(error) {
      console.error("Error retreiving operational catchment information", error);
    }
  }
};

export const getWaterBodyByName = (waterBodyName) => {
  return async (dispatch) => {
    try {
      await fetchData(
        dispatch,
        GET_WATERBODY_REQUEST,
        GET_WATERBODY_SUCCESS,
        GET_WATERBODY_FAILURE,
        `${baseUrl}/data/geographical/byName/waterBody/${waterBodyName.replaceAll('/', '-')}`,
        null,
        "untraced::getWaterBodyByName"
      );
    } catch(error) {
      console.error("Error getting water body data", error);
    }
  };
};

export const getOperationalCatchmentByName = (operationalCatchmentName) => {
  return async (dispatch) => {
    try {
      await fetchData(
        dispatch, 
        GET_OPERATIONAL_CATCHMENT_REQUEST, 
        GET_OPERATIONAL_CATCHMENT_SUCCESS, 
        GET_OPERATIONAL_CATCHMENT_FAILURE, 
        `${baseUrl}/data/geographical/byName/operationalCatchments/${operationalCatchmentName.replaceAll('/', '-')}`,
        null,
        "untraced::getOperationalCatchmentByName"
      );
    } catch(error) {
      console.error("Error retrieving operational catchment information", error);
    }
  };
};

export const getSearchPlaceByName = (placeName) => dispatch => {
  fetchData(
    dispatch,
      GET_SEARCHPLACE_REQUEST,
      GET_SEARCHPLACE_SUCCESS,
      GET_SEARCHPLACE_FAILURE,
      `${baseUrl}/data/geographical/byName/place/${placeName.replaceAll('/', '-')}`,
      null,
      "untraced::getSearchPlaceByName"
    );
};

export const getPlaceDefences = (placeId) => dispatch => {
  fetchData(
    dispatch,
      GET_PLACEDEFENCES_REQUEST,
      GET_PLACEDEFENCES_SUCCESS,
      GET_PLACEDEFENCES_FAILURE,
      `${baseUrl}/data/geographical/placeDefences/${placeId}`,
      null,
      "untraced::getPlaceDefences"
    );
};


export const getDocument = (documentId) => dispatch => {
  fetchData(dispatch, GET_DOCUMENT_REQUEST, GET_DOCUMENT_SUCCESS, GET_DOCUMENT_FAILURE, `${baseUrl}/documents/${documentId}`, null, "untraced::getDocument");
};

export const clearFloodMapActiveArea = () => dispatch => {
  dispatch({ type: CLEAR_ACTIVE_FLOODNAV });
  dispatch({ type: 'mapViewSelector/clearMapView' });
};

function bootstrapInitialState() {
  let defaultState = {
    inFlightRequestsByTrace: {},
    basins: [],
    basin: {},
    stations: {
      'defaults': {},
      'metric-list': {},
      'station-metadata': {},
      'Stage': {},
      'Flow': {},
      'Downstream Stage': {},
      'raw-data': {},
      'Sewage Discharge': {},
      'Rainfall': {}
    },
    stationsFlowModel: {},
    stationsHistorical: {},
    stationsHistoricalData: {},
    floodAlertsSummary: {},
    floodAlerts: {},
    riverSubUnit: {},
    managementCatchment: {},
    operationalCatchment: {},
    fmCoverageAreas: [],
    queue: 0,
    error: null,
    activeArea: null,
    activeWaterBody: null,
    waterBodies: {},
    areaDocumentsByName: {},
    viewHome: {},
    globalError: false,
    errorList: {},
    placeDefences: {},
    searchPlacesResults: {},
    mapping: {
      stations: []
    }
  };
  // if(typeof window !== 'undefined' && window.__INITIAL_STATE__) {

  //   for(let key of Object.keys(window.__INITIAL_STATE__)) {
  //     console.log("Bootstrap store data: ", key);
  //     defaultState[key] = window.__INITIAL_STATE__[key];
  //   }

  // }

  return defaultState;
}

// Initial State
const initialState = bootstrapInitialState();

// Reducer
function geographicalDataReducer(state = initialState, action) {
  let nextState = {
  		...state,
  		areaDocumentsByName: {
  			...state.areaDocumentsByName
  		}
  	};
  
  // Handlers for queue
  /*eslint no-fallthrough: "off"*/
  /*eslint default-case: "off"*/
  switch(action.type) {

    case CLEAR_ACTIVE_FLOODNAV:
      nextState.activeArea = null;
      break;

    case TRACK_REQUEST_START_BY_TRACE:
      if(action && action.callerTrace) {
        nextState.inFlightRequestsByTrace = {...state.inFlightRequestsByTrace};
        if(!nextState.inFlightRequestsByTrace[action.callerTrace]) {
          nextState.inFlightRequestsByTrace[action.callerTrace] = 0;
        }
        ++nextState.inFlightRequestsByTrace[action.callerTrace];
      }
      break;
    case TRACK_REQUEST_END_BY_TRACE:
      if(action && action.callerTrace) {
        nextState.inFlightRequestsByTrace = {...state.inFlightRequestsByTrace};
        if(!nextState.inFlightRequestsByTrace[action.callerTrace]) {
          nextState.inFlightRequestsByTrace[action.callerTrace] = 1;
        }
        --nextState.inFlightRequestsByTrace[action.callerTrace];
      }
      break;

  	// Request Incremement waiting queue
    case GET_MANAGEMENT_CATCHMENT_REQUEST:
    case GET_OPERATIONAL_CATCHMENT_REQUEST:
    case GET_BASINS_REQUEST:
    case GET_BASIN_REQUEST:
    case GET_FLOODMAPPER_COVERAGE_AREAS_REQUEST:
    case GET_FLOODMAPPER_SUBDOMAIN_REQUEST:
    case GET_STATION_REQUEST:
    case GET_STATION_HISTORICAL_REQUEST:
    case GET_FLOODALERTAREA_REQUEST:
    case GET_VIEW_HOME_REQUEST:
    case GET_FLOODALERTSUMMARY_REQUEST:
    case GET_WATERBODY_REQUEST:
    case GET_SEARCHPLACE_REQUEST:
    case GET_PLACEDEFENCES_REQUEST:
    case GET_STATION_HISTORICAL_WITH_STARTTIME_REQUEST:
    case GET_SEARCH_LOCAL_REQUEST:
    case GET_MAPPING_STATIONS_REQUEST:
    case GET_SEARCH_SEWAGE_REQUEST:
    case GET_RIVER_SUB_UNIT_REQUEST:
    case GET_STATION_FLOWMODEL_REQUEST:
      ++nextState.queue;
      nextState.globalError = false;
      break;

    // Request decrement waiting queue
    case GET_MANAGEMENT_CATCHMENT_SUCCESS:
    case GET_OPERATIONAL_CATCHMENT_SUCCESS:
    case GET_BASINS_SUCCESS:
    case GET_BASIN_SUCCESS:
    case GET_DOCUMENT_SUCCESS:
    case GET_FLOODMAPPER_COVERAGE_AREAS_SUCCESS:
    case GET_FLOODMAPPER_SUBDOMAIN_SUCCESS:
    case GET_STATION_SUCCESS:
    case GET_STATION_HISTORICAL_SUCCESS:
    case GET_FLOODALERTAREA_SUCCESS:
    case GET_VIEW_HOME_SUCCESS:
    case GET_FLOODALERTSSUMMARY_SUCCESS:
    case GET_WATERBODY_SUCCESS:
    case GET_SEARCHPLACE_SUCCESS:
    case GET_PLACEDEFENCES_SUCCESS:
    case GET_STATION_HISTORICAL_WITH_STARTTIME_SUCCESS:
    case GET_SEARCH_LOCAL_SUCCESS:
    case GET_MAPPING_STATIONS_SUCCESS:
    case GET_SEARCH_SEWAGE_SUCCESS:
    case GET_RIVER_SUB_UNIT_SUCCESS:
    case GET_STATION_FLOWMODEL_SUCCESS:
      nextState.globalError = false;
    case GET_MANAGEMENT_CATCHMENT_FAILURE:
    case GET_OPERATIONAL_CATCHMENT_FAILURE:
    case GET_BASINS_FAILURE:
    case GET_BASIN_FAILURE:
  	case GET_DOCUMENT_FAILURE:
  	case GET_FLOODMAPPER_COVERAGE_AREAS_FAILURE:
  	case GET_FLOODMAPPER_SUBDOMAIN_FAILURE:
    case GET_STATION_FAILURE:
    case GET_STATION_HISTORICAL_FAILURE:
    case GET_FLOODALERTAREA_FAILURE:
    case GET_VIEW_HOME_FAILURE:
    case GET_FLOODALERTSSUMMARY_FAILURE:
    case GET_WATERBODY_FAILURE:
    case GET_SEARCHPLACE_FAILURE:
    case GET_PLACEDEFENCES_FAILURE:
    case GET_STATION_HISTORICAL_WITH_STARTTIME_FAILURE:
    case GET_SEARCH_LOCAL_FAILURE:
    case GET_MAPPING_STATIONS_FAILURE:
    case GET_SEARCH_SEWAGE_FAILURE:
    case GET_RIVER_SUB_UNIT_FAILURE:
    case GET_STATION_FLOWMODEL_FAILURE:
      // Error handler
      nextState.globalError = true;
		  nextState.queue < 1 ? nextState.queue = 0 : --nextState.queue;
      // All handler
      if(action.callerTrace) {
        nextState.errorList = { ...state.errorList };
        nextState.errorList[action.callerTrace] = action.type.endsWith("_FAILURE");
      }
		  break;
  }

  switch(action.type) {
    case GET_STATION_FLOWMODEL_SUCCESS:
      nextState.stationsFlowModel = { ...state.stationsFlowModel };
      if(action.payload && action.payload.stationId && Array.isArray(action.payload.modelData)) {
        nextState.stationsFlowModel[action.payload.stationId] = action.payload.modelData;
        if(action.payload.urlSlug) {
          nextState.stationsFlowModel[action.payload.urlSlug] = nextState.stationsFlowModel[action.payload.stationId];
        }
      }
      break;
    case GET_MAPPING_STATIONS_SUCCESS:
      nextState.mapping = { ...state.mapping };
      if(action.payload && action.payload.stations) {
        nextState.mapping.stations = action.payload.stations;
      }
      break;
    case GET_SEARCH_SEWAGE_SUCCESS:
      nextState.searchSewageResults = { ...state.searchSewageResults };
      if(action.payload && action.payload.placeId) {
        nextState.searchSewageResults[action.payload.placeId] = action.payload;
      }
      break;
    case GET_SEARCH_LOCAL_SUCCESS:
      nextState.searchPlacesResults = { ...state.searchPlacesResults };
      if(action.payload && action.payload.placeId) {
        nextState.searchPlacesResults[action.payload.placeId] = action.payload;
      }
      break;
  	case GET_BASINS_SUCCESS:
  		nextState.basins = action.payload;
  		break;
    case GET_BASIN_SUCCESS:
      nextState.basin = { ...state.basin };
      nextState.basin[action.payload.riverBasinName] = action.payload;
      break;
    case GET_RIVER_SUB_UNIT_SUCCESS:
      nextState.riverSubUnit = { ...state.riverSubUnit };
      nextState.riverSubUnit[action.payload.subUnitName] = action.payload;
      break;
    case GET_MANAGEMENT_CATCHMENT_SUCCESS:
      nextState.managementCatchment = { ...state.managementCatchment };
      nextState.managementCatchment[action.payload.mgmtCatchmentName] = action.payload;
      break;
    case GET_OPERATIONAL_CATCHMENT_SUCCESS:
      nextState.operationalCatchment = { ...state.operationalCatchment };
      nextState.operationalCatchment[action.payload.operCatchmentName] = action.payload;
      break;
  	case GET_FLOODMAPPER_COVERAGE_AREAS_SUCCESS:
  		nextState.fmCoverageAreas = action.payload.sort((a, b) => 
			a.locationName.localeCompare(b.locationName)
		);
  		break;
  	case GET_FLOODMAPPER_SUBDOMAIN_SUCCESS:
  		nextState.areaDocumentsByName[action.areaName] = action.payload;
  		if(action.setAsActiveArea) {
  			nextState.activeArea = action.payload;
  		}
  		break;
    case GET_STATION_SUCCESS:
      console.log(action.payload)
      nextState.stations = { ...state.stations };
      if(action && action.payload) {

        if(action.payload.qualifier) {
          // Write the state for this station into the stations list
          if(nextState.stations[action.payload.qualifier]) {
            nextState.stations[action.payload.qualifier] = { ...nextState.stations[action.payload.qualifier] };
          } else {
            nextState.stations[action.payload.qualifier] = {};
          }
          nextState.stations[action.payload.qualifier][action.payload.stationId] = action.payload;
          if(action.payload.urlSlug) {
            nextState.stations[action.payload.qualifier][action.payload.urlSlug] = action.payload;
          }
        } else {
          nextState.stations['raw-data'] = {...nextState.stations['raw-data'] };
          nextState.stations['raw-data'][action.payload.stationId] = action.payload;
          if(action.payload.urlSlug) {
            nextState.stations['raw-data'][action.payload.urlSlug] = action.payload;
          }
        }

        // Set the default station qualifier for this station, only if its present (backwards compat.)
        if(action.payload.stationPrimaryMetricType) {
          nextState.stations['defaults'] = { ...nextState.stations['defaults'] };
          nextState.stations['defaults'][action.payload.stationId] = action.payload.stationPrimaryMetricType;
          if(action.payload.urlSlug) {
            nextState.stations['defaults'][action.payload.urlSlug] = action.payload.stationPrimaryMetricType;
          }
        }

        if(action.payload.metricList) {
          nextState.stations['metric-list'] = { ...nextState.stations['metric-list'] };
          nextState.stations['metric-list'][action.payload.stationId] = action.payload.metricList.reduce(function(acca, metric) {
            acca[metric.metricId] = {
              qualifier: metric.qualifier
            };
            return acca;
          }, {});
          if(action.payload.urlSlug) {
            nextState.stations['metric-list'][action.payload.urlSlug] = nextState.stations['metric-list'][action.payload.stationId];
          }
        }

        if(action.payload.stationLat && action.payload.stationLng) {
          nextState.stations['station-metadata'] = { ...nextState.stations['station-metadata'] };
          let subsequentAccess = false;
          if(nextState.stations['station-metadata'][action.payload.stationId]) {
            subsequentAccess = true;
          }
          nextState.stations['station-metadata'][action.payload.stationId] = {
            urlSlug: action.payload.urlSlug,
            stationLat: action.payload.stationLat,
            stationLng: action.payload.stationLng,
            stationName: action.payload.stationName,
            stationId: action.payload.stationId,
            townName: action.payload.townName,
            riverName: action.payload.riverName,
            riverBasinName: action.payload.riverBasinName,
            subUnitName: action.payload.subUnitName,
            mgmtCatchmentName: action.payload.mgmtCatchmentName,
            operCatchmentName: action.payload.operCatchmentName,
            waterBodyName: action.payload.waterBodyName,
            upstreams: action.payload.upstreams ? action.payload.upstreams.sort((a, b) => {
  if (a.stationName < b.stationName) return -1;
  if (a.stationName > b.stationName) return 1;
  return 0; // equal stationName
}) : [],
            downstreams: action.payload.downstreams ? action.payload.downstreams.sort((a, b) => {
  if (a.stationName < b.stationName) return -1;
  if (a.stationName > b.stationName) return 1;
  return 0; // equal stationName
}) : [],
            subsequentAccess: subsequentAccess
          };

          if(action.payload.urlSlug) {
            nextState.stations['station-metadata'][action.payload.urlSlug] = nextState.stations['station-metadata'][action.payload.stationId];
          }
        }

      }
      // nextState.stations[action.payload.stationId] = action.payload;
      // if(!action.payload.qualifier || action.payload.qualifier === 'Stage') {
      //   nextState.stations['Stage'] = { ...nextState.stations['Stage'] };
      //   nextState.stations['Stage'][action.payload.stationId] = action.payload;
      // } else if(action.payload.qualifier === 'Downstream Stage') {
      //   nextState.stations['Downstream Stage'] = { ...nextState.stations['Downstream Stage'] };
      //   nextState.stations['Downstream Stage'][action.payload.stationId] = action.payload;
      // }
      break;
    case GET_STATION_HISTORICAL_WITH_STARTTIME_SUCCESS:
      nextState.stationsHistoricalData = { ...state.stationsHistoricalData };
      if(!nextState.stationsHistoricalData[action.payload.stationId]) {
        nextState.stationsHistoricalData[action.payload.stationId] = {};
        if(action.payload.urlSlug) {
          nextState.stationsHistoricalData[action.payload.urlSlug] = {};
        }
      } else {
        nextState.stationsHistoricalData[action.payload.stationId] = {...state.stationsHistoricalData[action.payload.stationId]};
        if(action.payload.urlSlug) {
          nextState.stationsHistoricalData[action.payload.urlSlug] = nextState.stationsHistoricalData[action.payload.stationId];
        }
      }
      if(action.payload.historicalRecord && action.payload.historicalRecord.event) {
        let eventIdentifier = action.payload.historicalRecord.event.startTimestampFormatted;
        nextState.stationsHistoricalData[action.payload.stationId][eventIdentifier] = action.payload.historicalRecord;
        if(action.payload.urlSlug) {
          nextState.stationsHistoricalData[action.payload.urlSlug][eventIdentifier] = nextState.stationsHistoricalData[action.payload.stationId][eventIdentifier];
        }
      }
      // Intentional Fallthrough
    case GET_STATION_HISTORICAL_SUCCESS:
      nextState.stationsHistorical = { ...state.stationsHistorical };
      nextState.stationsHistorical[action.payload.stationId] = action.payload;
      if(action.payload.urlSlug) {
        nextState.stationsHistorical[action.payload.urlSlug] = nextState.stationsHistorical[action.payload.stationId];
      }
      break;
    case GET_FLOODALERTSSUMMARY_SUCCESS:
      nextState.floodAlertsSummary = action.payload;
    case GET_FLOODALERTAREA_SUCCESS:
      nextState.floodAlerts = { ...state.floodAlerts };
      // be careful with this because we are translating between id and fwisCode
      nextState.floodAlerts[action.payload.fwisCode] = action.payload;
      break;
    case GET_VIEW_HOME_SUCCESS:
      nextState.viewHome = action.payload;
      break;
    case GET_WATERBODY_SUCCESS:
      nextState.waterBodies = { ...state.waterBodies };
      nextState.waterBodies[action.payload.waterBodyName] = action.payload;
      break;
    case GET_PLACEDEFENCES_SUCCESS:
      nextState.placeDefences = { ...state.placeDefences };
      nextState.placeDefences[action.payload.place.placeId] = action.payload;
      break;
  }
  // Default route, return state (may have been mutated)
  return nextState;
}

export default geographicalDataReducer;

